/* css */
@import "https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;600;700&family=Heebo:wght@300;400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Heebo:wght@300;400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');

@import '~antd/dist/antd.css';

html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  overflow-x: hidden;

  background-color: #fff;

  font-family: heebo,sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #60698d;
}

div.ant-row h1, 
div.ant-row h2, 
div.ant-row h3, 
div.ant-row h4, 
div.ant-row h5, 
div.ant-row h6 {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 700;
  color: #212573;
}

h5 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #010101;
  margin: 0;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.blue { color: #212573 !important;}
.orange { color: #FD5E33 !important;}
.lightblue { color: #D3D3E7 !important; }
.white { color: #fff !important }
.vlightblue { color: #ededf5 !important }
.gray { color: #010101 !important;}

.ant-typography-white { color: #fff !important }

.material-icons.blue { color: #212573; }
.material-icons.orange { color: #FD5E33 }
.material-icons.lightblue { color: #D3D3E7 }
.material-icons.gray { color: #010101 }

.largeIcon { font-size: 70px; }

.sticky {
  position: fixed !important;
  width: 100%;
  z-index: 99;
  box-shadow: 0 20px 50px 0 rgba(0,0,0,.05);
  transition: all .2s ease-out 0s;
  margin: 0 auto;
}

.sticky li:hover a:before,
.sticky li a.active:before {
  background: -webkit-linear-gradient(left,rgba(255,255,255,0.1) 0%,#212573 100%) !important;
  background: -o-linear-gradient(left,rgba(255,255,255,0.1) 0%,#212573 100%) !important;
  background: linear-gradient(to right,rgba(255,255,255,0.1) 0%,#212573 100%) !important; 
}

/*

  #212573 blauw
  #FD5E33 oranje
  #D3D3E7 Kalkblauw
  #010101 Zwartgrijs
*/

div.ant-row {
  margin: 0 auto;
  width: 90%;
  max-width: 1300px;
}

.biggerIcon svg {
  height: 24px;
  width: 24px;
  margin: 15px;
}

.ant-image-preview-img {
  transform: scale3d(1.4, 1.4, 1.4) rotate(0deg) !important;
}

.productCard .ant-card-meta-avatar {
    height: 230px;
    width: 140px;
    margin: -20px 10px 0 -25px;
}

.productCard .ant-image-mask {
    height: 230px;
    width: 140px;
    margin: 0px 10px 0 0px;
}

.productCard .ant-card-body {
  padding: 24px 24px 0px 24px;
}